////////////////////////////////////////////////////////////////////////////
//
// Copyright 2020 Realm Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
////////////////////////////////////////////////////////////////////////////
class DefaultNetworkTransport {
    constructor() {
        if (!DefaultNetworkTransport.fetch) {
            throw new Error("DefaultNetworkTransport.fetch must be set before it's used");
        }
        if (!DefaultNetworkTransport.AbortController) {
            throw new Error("DefaultNetworkTransport.AbortController must be set before it's used");
        }
    }
    fetchWithCallbacks(request, handler) {
        // tslint:disable-next-line: no-console
        this.fetch(request)
            .then(async (response) => {
            const decodedBody = await response.text();
            // Pull out the headers of the response
            const responseHeaders = {};
            response.headers.forEach((value, key) => {
                responseHeaders[key] = value;
            });
            return {
                statusCode: response.status,
                headers: responseHeaders,
                body: decodedBody,
            };
        })
            .then((r) => handler.onSuccess(r))
            .catch((e) => handler.onError(e));
    }
    async fetch(request) {
        const { timeoutMs, url, ...rest } = request;
        const { signal, cancelTimeout } = this.createTimeoutSignal(timeoutMs);
        try {
            // We'll await the response to catch throw our own error
            return await DefaultNetworkTransport.fetch(url, {
                ...DefaultNetworkTransport.extraFetchOptions,
                signal,
                ...rest,
            });
        }
        finally {
            // Whatever happens, cancel any timeout
            cancelTimeout();
        }
    }
    createTimeoutSignal(timeoutMs) {
        if (typeof timeoutMs === "number") {
            const controller = new DefaultNetworkTransport.AbortController();
            // Call abort after a specific number of milliseconds
            const timeout = setTimeout(() => {
                controller.abort();
            }, timeoutMs);
            return {
                signal: controller.signal,
                cancelTimeout: () => {
                    clearTimeout(timeout);
                },
            };
        }
        else {
            return {
                signal: undefined,
                cancelTimeout: () => {
                    /* No-op */
                },
            };
        }
    }
}
DefaultNetworkTransport.DEFAULT_HEADERS = {
    "Content-Type": "application/json",
};

////////////////////////////////////////////////////////////////////////////
const globalThisOrWindow = typeof globalThis === "object" ? globalThis : window;
DefaultNetworkTransport.fetch = globalThisOrWindow.fetch.bind(globalThisOrWindow);
DefaultNetworkTransport.AbortController = globalThisOrWindow.AbortController.bind(globalThisOrWindow);

export { DefaultNetworkTransport };
